import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css, styled } from "goober";
import Card from "clutch/src/Card/Card.jsx";
import { mobile } from "clutch/src/Style/style.mjs";
import i18n from "i18next";

import { readState } from "@/__main__/app-state.mjs";
import type { Meta } from "@/__main__/router.mjs";
import AgentAbilitiesPlayer from "@/game-val/AgentAbilitiesPlayer.jsx";
import AgentCard from "@/game-val/AgentCard.jsx";
import AgentMapGuides from "@/game-val/AgentMapGuides.jsx";
import AgentYoutubeVideos from "@/game-val/AgentYoutubeVideos.jsx";
import { AGENT_CLASS_ICONS } from "@/game-val/constants/constants-class-icons.mjs";
import type { AgentInsights, Agents } from "@/game-val/models/agents.mjs";
import { getHSLColor } from "@/game-val/utils.mjs";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import groupBy from "@/util/group-by.mjs";
import keyInObject from "@/util/key-in-object.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const ContentWrapper = styled("div")`
  margin-top: var(--sp-4);
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: var(--sp-4);
  ${mobile} {
    grid-template-columns: 1fr;
  }
`;

const MainContent = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 2;
`;

const SideContent = () => css`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: fit-content;
  div.padded {
    padding: var(--sp-6);
    flex-direction: column;
    flex: 1;
  }
  img {
    max-width: 100%;
  }
`;

const UnderTitle = styled("div")`
  display: flex;
  align-items: center;
  color: var(--shade2);
  svg {
    height: var(--sp-5);
    width: var(--sp-5);
    margin-right: var(--sp-1);
  }
  span {
    font-size: var(--sp-3_5e);
  }
`;

const InsightsComingSoon = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  background: var(--shade8);
  color: var(--shade2);
  border-radius: var(--br);
`;

const InsightGroup = styled("div")`
  &:not(:last-child) {
    margin-bottom: var(--sp-6);
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  li {
    margin-bottom: var(--sp-3);
  }
  .group-title {
    margin-bottom: var(--sp-2);
    &.title-offense {
      color: var(--red);
    }
    &.title-defense {
      color: var(--turq);
    }
    &.title-general {
      color: var(--shade0);
    }
  }
`;

const TipText = styled("p")<{
  $agentColor?: string | null;
}>`
  span {
    font-weight: 700;
    color: ${({ $agentColor }) =>
      $agentColor ? `hsl(${$agentColor})` : "var(--shade2)"};
  }
`;

const TipSubtext = styled("span")`
  color: var(--shade1);
`;

interface AgentInsightProps {
  agent: Agents[number];
  insight: AgentInsights["insightsCollection"]["items"][number];
}

const AgentInsight: React.FC<AgentInsightProps> = ({ agent, insight }) => {
  const agentColor = getHSLColor(agent.color);
  return (
    <div>
      <TipText
        $agentColor={agentColor}
        className="type-body2"
        dangerouslySetInnerHTML={{ __html: insight.tip }}
      />
      {insight.extra && (
        <TipSubtext className="type-caption">{insight.extra}</TipSubtext>
      )}
    </div>
  );
};

type CMSAgent = Agents[number];
type MetaAgentInsights = AgentInsights["insightsCollection"]["items"];

interface AgentData {
  agent: CMSAgent | null;
  agentClass: string;
  AgentClassIcon: SVGComponent | null;
  agentInsights: Record<string, MetaAgentInsights>;
  key: string;
  name: string;
  videos: AgentInsights["ytVideos"];
}

export default function Agent() {
  const { t } = useTranslation();

  const {
    val: {
      cms: { agents, agentInsights: allAgentInsights },
    },
  } = useSnapshot(readState);

  const {
    parameters: [tab],
  } = useRoute();

  const links = useMemo(
    () =>
      [...agents]
        .sort((l, r) => l.key.localeCompare(r.key))
        .map(({ key, name }) => {
          return {
            url: `/valorant/agents/${key}`,
            text: t(`val.agents.${key}`, name),
          };
        }),
    [t, agents],
  );

  const cmsAgent = agents?.find(({ key }) => key === tab) as CMSAgent;
  const currentAgentInsights = allAgentInsights[cmsAgent?.sys.id];

  const {
    agent,
    agentClass,
    AgentClassIcon,
    agentInsights,
    key,
    videos,
    name,
  } = useMemo((): AgentData => {
    const { key, name, class: agentClass } = cmsAgent ?? {};
    const {
      insightsCollection: { items: insights = [] } = {},
      ytVideos: videos = [],
    } = currentAgentInsights || {};
    const AgentClassIcon =
      agentClass && keyInObject(AGENT_CLASS_ICONS, agentClass)
        ? AGENT_CLASS_ICONS[agentClass]
        : null;
    const agentInsights = groupBy(insights as MetaAgentInsights, "side");
    return {
      agent: cmsAgent,
      agentClass,
      AgentClassIcon,
      agentInsights,
      key,
      name,
      videos: videos as AgentData["videos"],
    };
  }, [cmsAgent, currentAgentInsights]);

  if (!agent) return null;

  return (
    <PageContainer>
      <PageHeader
        title={t(`val:agents.${key}`, name)}
        ImageComponent={<AgentCard agent={agent} />}
        links={links}
        underTitle={
          <UnderTitle>
            {AgentClassIcon ? <AgentClassIcon /> : null}
            <span className="type-body2 ">
              {t(`val:agentClass.${agentClass.toLowerCase()}`, agentClass)}
            </span>
          </UnderTitle>
        }
      />
      <ContentWrapper>
        <MainContent>
          <AgentAbilitiesPlayer agentKey={agent.key} />
          {videos?.length ? (
            <AgentYoutubeVideos videos={videos} name={name} />
          ) : null}
        </MainContent>
        <Card padding="0" className={SideContent()}>
          <AgentMapGuides agent={agent} />
          <div className="padded">
            {Object.values(agentInsights).length === 0 && (
              <InsightsComingSoon>
                <p className="type-body2-form--active">
                  {t(
                    "val:insightsComingSoon",
                    "{{name}} Insights Coming Soon!",
                    { name },
                  )}
                </p>
              </InsightsComingSoon>
            )}
            {Object.entries(agentInsights).map(([grpTitle, insights]) => (
              <InsightGroup key={grpTitle}>
                <span className="type-subtitle2 group-title title-general">
                  {grpTitle}
                </span>
                <ul>
                  {insights.map((insight) => (
                    <li key={insight.tip}>
                      <AgentInsight agent={agent} insight={insight} />
                    </li>
                  ))}
                </ul>
              </InsightGroup>
            ))}
          </div>
        </Card>
      </ContentWrapper>
    </PageContainer>
  );
}

export function meta([tab]: [string]): Meta {
  const { t } = i18n;
  const agent =
    (readState.val.cms?.agents || []).find((o) => o.key === tab)?.name || tab;
  const agentName = t(`val:agents.${tab}`, agent);

  return {
    title: [
      "val:meta.agent.title",
      "Valorant {{agentName}} Stats, Abilities & Performance Insights | Blitz",
      { agentName },
    ],
    description: [
      "val:meta.agent.description",
      "Discover everything about Valorant's {{agentName}} on Blitz. Get detailed stats, abilities breakdown, and strategies to dominate with {{agentName}} in your next match. Stay ahead in competitive play with expert tips.",
      { agentName },
    ],
    subtitle: [
      "val:meta.agent.subtitle",
      "Discover VALORANT {{agentName}}'s abilities and how to play them.",
      { agentName },
    ],
  };
}
